import React, {useEffect, useState} from 'react';
import classes from "./statistic.module.css";
import {getStatistics} from "../../utils/api";

const Statistic = () => {
    const [countries, setCountries] = useState([]);
    const [configs, setConfigs] = useState(0);
    const [clients, setClients] = useState(0);

    useEffect(()=>{
        const getStatisticsT = async () => {
            const res = await getStatistics()
            if(res.status === 'OK'){
                setCountries(res.res.countries)
                setClients(res.res.clients)
                setConfigs(res.res.configs)
            }
        }
        getStatisticsT();
    },[])
    return (
        <div className={classes._}>
            <div className={classes.header}>Статистика</div>
            <div className={classes.globe}>
                <div className={classes.text}>Всего стран</div>
                <div className={classes.count}>{countries.length}</div>
                <div className={classes.globe_worldmap}>
                    <div className={classes.globe_worldmap_back}></div>
                    <div className={classes.globe_worldmap_front}></div>
                </div>
                <div className={classes.globe_inner_shadow}></div>
            </div>
            <div className={classes.globe}>
                <div className={classes.text}>Пользователей</div>
                <div className={classes.count}>{clients}</div>
                <div className={classes.globe_worldmap}>
                    <div className={classes.globe_worldmap_back}></div>
                    <div className={classes.globe_worldmap_front}></div>
                </div>
                <div className={classes.globe_inner_shadow}></div>
            </div>
            <div className={classes.globe}>
                <div className={classes.text+' '+classes.text2}>Активных конфигураций</div>
                <div className={classes.count}>{configs}</div>
                <div className={classes.globe_worldmap}>
                    <div className={classes.globe_worldmap_back}></div>
                    <div className={classes.globe_worldmap_front}></div>
                </div>
                <div className={classes.globe_inner_shadow}></div>
            </div>
        </div>
    );
};

export default Statistic;