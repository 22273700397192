import React from 'react';
import classes from "./firstBlock.module.css";

const FirstBlock = () => {
    return (
        <div>
            <div className={classes.text1}><div className={classes.text2}>EasyFastVPN</div><div>- простой быстрый VPN</div></div>
            <div className={classes.textBlock}>
                <div className={classes.textInfo}>🔒 Защити себя с помощью нашего VPN!</div>
                <div className={classes.textInfo}>🚀 Высокая скорость (до 200МБит/с).</div>
                <div className={classes.textInfo}>🐉 Официальный клиент WireGuard.</div>
                <div className={classes.textInfo}>📱💻 Любое устройство (Android, IPhone, MacBook, Windows).</div>
                <div className={classes.textInfo}>🇷🇺🇮🇹 Выбор страны.</div>
                <div className={classes.textInfo}>💳 Стоимость 100₽/мес за 3 VPN-сервера.</div>
                <div className={classes.textInfo}>💸 Первый месяц бесплатно.</div>
            </div>
            <div className={classes.text3}><a href={'https://t.me/EasyFastVPNBot?start=NDgwNjQyMTE0'} target={"_blank"}>
                <button className={classes.custom_btn+' '+classes.btn_12}><span>Пользоваться</span><span>Начать</span></button></a></div>
            <div className={classes.header}>Карта серверов</div>
        </div>
    );
};

export default FirstBlock;